/**
 * Описание класса событий.
 * Создание экземпляра
 * Общие события
 */

import EventEmitter from 'events';

import { antNotification } from 'react-UI';

window.antNotification = antNotification;

class Dispatcher extends EventEmitter {
	constructor() {
		super();
	}
}

window.dispatcher = new Dispatcher();

/**
 * Сообщение о недоступности
 */
window.dispatcher.on('notUsed', options => {
	alert('Функционал временно не доступен.')
});

/**
 * Открыть дерево объектов в новом таббаре
 */
window.dispatcher.on('showDO', options => {
	y.f.AddDONew(options.winId ? options.winId : y.p.curWin.split("winId")[1], {
		root: options.root,
		label: options.label
	});
});

/**
 * Откыть новую вкладку
 */
window.dispatcher.on('mainMenu', options => {
	m.m.MainMenu(options[0], options[1], options[2]);
});

/**
 * Откыть новую вкладку с картой
 */
window.dispatcher.on('createMap', options => {
	oe.map.create(options, options.callback);
});

/**
 * Обновить слой на карте
 */
window.dispatcher.on('refreshMap', options => {
	if (options.layerId) {
		if (oe.map.map.layers[options.layerId]) {
			console.log(oe.map.map.layers[options.layerId]);
			oe.map.map.layers[options.layerId].redraw();
		}
	} else {
		Object.keys(oe.map.map.layers).forEach(function (layerName) {
			if (oe.map.map.layers[layerName].visible === true) {
				oe.map.map.layers[layerName].redraw();
			}
		});
	}
});


/**
 * Откыть новую вкладку с чатом
 */
window.dispatcher.on('chatOpen', options => {
	new oe.chat.Tab('chat', 'Чат');
});

/**
 * Откыть квадратор
 */
window.dispatcher.on('showVideo', options => {
	oe.showVideo(options);
});

/**
 * Откыть на просмотр
 */
window.dispatcher.on('View', options => {
	['id', 'title', 'mode', 'cgivar', 'numWin'].forEach(item => {
		if (!options[item]) {
			options[item] = ''
		}
	});
	let isActiveTab = false;
	Object.keys(y.p.tab).forEach(item => {
		if (y.p.tab[item]
			&& y.p.tab[item][0].split(',')[3] === options.id
			&& options.type !== 'ea02ba2ff3c359c160845659f5824e20c' /* хак для проекта fd и деклараций */
			&& options.type !== 'f8395a67696918a748a730fc87983ce6b' /* хак для проекта fd и оил */) {
			options.target = $('#' + y.p.tab[item][0].split(',')[0]);
			y.f.setTabActiveByMark('PP', options.id);
			y.dhtmlx.wins.forEachWindow(function (win) {
				if (win.getId() === 'winId' + y.p.tab[item][1]) {//текст текущего окна будет содержать лэйбэл текущего таба
					win.setText($('#H_' + item).children('div').eq(2).children('div').attr('title'));
				}
			});
			isActiveTab = true;
		}
	});
	if (isActiveTab === true) {
		return;
	}
	$.ajax({
		metod: 'GET'
		, url: 'objects/' + options.id
		, datatype: 'json'
	}).done(function (response) {
		if (response.ok === false) {
			console.error(response);
			return m.m.PP(options.id, options.title, options.mode, options.cgivar, options.numWin, options);
		} else {
			response = response.object;
			options.title = response.name;
		}
		if (response.parent == 'A4893650o15903o64342o51680o623267' || response.parent == 'A4893649o15903o64342o51665o958218') {
			if (response.object.type == '-Container') {
				$.ajax({
					metod: 'GET'
					, url: 'getGlossaryItem?query=' + encodeURI(JSON.stringify({ parent: options.id }))
					, datatype: 'json'
				}).done(function (res) {
					if (!Object.keys(res)[0]) {
						m.m.PP(options.id, options.title, options.mode, options.cgivar, options.numWin, options);
					}
					alternativePPObj('A4893753o21492o64343o66720o639356', Object.keys(res)[0], response.name, 'sptp');
				}).fail(function (e) {
					console.error(e);
					m.m.PP(options.id, options.title, options.mode, options.cgivar, options.numWin, options);
				});
			} else {
				m.m.PP(options.id, options.title, options.mode, options.cgivar, options.numWin, options);

			}
		} else {

			if (['geolocator', 'hectare', 'pozhar'].includes(y.p.proj)) {
				if (response.mode === 'w') {
					var opt = {id: options.id, mode: 'Edit'/*, type: response.object.type*/};
					if (response.object.type == 'A4894703o5150o64355o42904o802167') {
						Object.keys(y.p.tab).forEach(function(item){
							var ttab = y.p.tab[item][0].split(',');
							if (ttab[1] === 'ED') {
								if ($('#' + ttab[0]).length > 0 && !$('#' + ttab[0]).data('sky').isNew && $('#' + ttab[0]).data('sky').o.form.type === 'A4894703o5150o64355o42904o802167' && !$('#' + ttab[0]).data('sky').changed) {
									opt.tn = item;
								}
							}
						})
					}
					window.dispatcher.emit('runMenu', opt);
				} else {
					if (response.stat.alternativeView) {
						alternativePPObj(response.stat.alternativeView, options.id, response.name, '');
					} else {
						m.m.PP(options.id, options.title, options.mode, options.cgivar, options.numWin, options);
					}
				}
			} else {
				switch (response.object.type) {/** открывать объект сразу на редактирорвание */
					//case 'layerType':							 // Картографический слой
					case '-Type':
					case '-Container':
					case '-GlossaryItem':
					//case 'af78beb50df0bbacb612a14c361aac5bb':	 // ОИЛ
					//case 'dd8b3e636a90e24c5ef464c8004b4ec64':  // ЛХ ОИЛ
					case '38b4bb483011c3d7393a4fdd9f4ce9aa':
					//case 'fa7c4ee044008b9c88c7d6787322682f7':  // арендатор
					//case 'd40ab6f460ff0c3c196288ad240f52892':  // договор аренды
					case 'A4893384o19374o64331o49152o529609':
					case 'A4893472o31354o64336o62046o940487':
					case 'A4894703o5150o64355o42904o802167':
					//case 'ea02ba2ff3c359c160845659f5824e20c':	 // ЛД
					case 'f8395a67696918a748a730fc87983ce6b':
					//case 'c1c7e061a1daa36aed979f59d9c976d7a':	 // ЛХ ЛД
					case 'e15231b929670066179e82559a5c7b282':
					//case 'd82f1864020df7be02eb4dba5f3cb80c2':  // бпла
					//case 'd96aa50516802c7224473ab22884e7889':  // миссия бпла
					case 'a79a9357f185acfbfb8d492cd0050ff97':
					case 'd7514ed85c23d62a275d4f305beaa64c6':
					//case 'a347611c9eab33a690e01f9c11122e4ef':  // Лесной участок
					case 'f2dd03949f18ed152cbf96124cfe37a7f':
					case 'e7477edcd693fbc484364bb7174ad102a':
					case 'e3ce79bd2c49056fb618e2ab7f2c89b25':

					case 'efd4f0b417b7bdd45bba821b258924f5b':
					case 'd8ec43fac98b8947d1e3c19f28f72d6ad':
					case 'fe57e7863a72fddc021a5fde1ee08f617': // Перечётная ведомость
					case 'd379e2e21d2b008635b06708e3f0803a0': // Полёт
						var opt = { id: options.id, mode: 'Edit'/*, type: response.object.type*/ };
						if (response.object.type == 'A4894703o5150o64355o42904o802167') {
							Object.keys(y.p.tab).forEach(function (item) {
								var ttab = y.p.tab[item][0].split(',');
								if (ttab[1] === 'ED') {
									if ($('#' + ttab[0]).length > 0 && !$('#' + ttab[0]).data('sky').isNew && $('#' + ttab[0]).data('sky').o.form.type === 'A4894703o5150o64355o42904o802167' && !$('#' + ttab[0]).data('sky').changed) {
										opt.tn = item;
									}
								}
							})
						}
						window.dispatcher.emit('runMenu', opt);
						break;
					default:
						if (response.stat.alternativeView) {
							alternativePPObj(response.stat.alternativeView, options.id, response.name, '');
						} else {
							m.m.PP(options.id, options.title, options.mode, options.cgivar, options.numWin, options);
						}
				}
				/*if (response.object.type == 'layerType' || response.object.type == 'd40ab6f460ff0c3c196288ad240f52892' || response.object.type == 'A4893384o19374o64331o49152o529609' || response.object.type == 'A4893472o31354o64336o62046o940487' || response.object.type == 'A4894703o5150o64355o42904o802167') {
					var opt = {id: options.id, mode: 'Edit'/!*, type: response.object.type*!/};
					if (response.object.type == 'A4894703o5150o64355o42904o802167') {
						Object.keys(y.p.tab).forEach(function(item){
							var ttab = y.p.tab[item][0].split(',');
							if (ttab[1] === 'ED') {
								if ($('#' + ttab[0]).length > 0 && !$('#' + ttab[0]).data('sky').isNew && $('#' + ttab[0]).data('sky').o.form.type === 'A4894703o5150o64355o42904o802167' && !$('#' + ttab[0]).data('sky').changed) {
									opt.tn = item;
								}
							}
						})
					}
					window.dispatcher.emit('runMenu', opt);
				} else {
					if (response.stat.alternativeView) {
						alternativePPObj(response.stat.alternativeView, options.id, response.name, '');
					} else {
						m.m.PP(options.id, options.title, options.mode, options.cgivar, options.numWin, options);
					}
				}*/
			}
		}
	}).fail(function (e) {
		console.error(e);
		m.m.PP(options.id, options.title, options.mode, options.cgivar, options.numWin, options);
	});
});

/**
 * Выполнить пункт меню
 */
window.dispatcher.on('runMenu', options => {
	['id', 'mode', 'type', 'plugPPRun'].forEach(item => {
		if (!options[item]) {
			options[item] = ''
		}
	});
	if (options.mode === 'Edit') {
		if (options.id) {
			getObjects(options.id, function (err, obj) {
				if (err) {
					console.error(err);
					return alert(err);
				}
				options.type = obj.type;
				if (obj.mode === 'r') {
					window.dispatcher.emit('View', options);
					return;
				}
				options.infoPanel = {
					"a": options.id,
					"b": "«" + obj.name + "»",
					"c": obj.type,
					"d": "«" + (oeType[obj.type] ? oeType[obj.type].name : obj.type) + "»",
					"e": "Тип"
				};
				options.objName = obj.name;
				unSelected(options);
				m.m.RunCMenu(options.mode, options.id, 1, options.type, options.plugPPRun, options);
			});
		} else {
			unSelected(options);
			m.m.RunCMenu(options.mode, options.id, 1, options.type, options.plugPPRun, options);
		}
	} else {
		if (!options.type) { //не указан тип. надо посмотреть
			if (options.id) {
				getObjects(options.id, function (err, obj) {
					if (err) {
						console.error(err);
						return alert(err);
					}
					options.type = obj.type;
					options.infoPanel = {
						"a": options.id,
						"b": "«" + obj.name + "»",
						"c": obj.type,
						"d": "«" + (oeType[obj.type] ? oeType[obj.type].name : obj.type) + "»",
						"e": "Тип"
					};
					options.objName = obj.name;
					unSelected(options);
					m.m.RunCMenu(options.mode, options.id, 1, options.type, options.plugPPRun, options);
				});
			} else {
				unSelected(options);
				m.m.RunCMenu(options.mode, options.id, 1, options.type, options.plugPPRun, options);
			}
		} else {
			unSelected(options);
			m.m.RunCMenu(options.mode, options.id, 1, options.type, options.plugPPRun, options);
		}
	}
});

/**
 * Переименовать объект
 */
window.dispatcher.on('rename', options => {
	$.ajax({
		metod: 'GET'
		, url: 'objects/' + options.id
		, datatype: 'json'
	}).done(function (response) {
		if (response.ok === false) {
			console.error(response);
		} else {
			response = response.object;
		}
		var name = response.name;
		var str = prompt('Введите новое имя объекта _________________________________________', name);
		if (str != null && str != '') {
			Object.keys(response.system).forEach(function (cat) {
				if (response.system[cat].name && response.system[cat].name == name) {
					response.system[cat].name = str;
				}
			});
			$.ajax({
				method: 'POST'
				, url: '/objects/' + options.id
				, datatype: 'json'
				, data: {
					object: JSON.stringify(response.object)
					, system: JSON.stringify(response.system)
					, project: y.p.proj
				}
			}).done(function (res) {
				[response.parent].concat(response.link).forEach(function (nodeId) {
					window.dispatcher.emit('refreshNode', nodeId);
				});
			}).fail(function (e) {
				console.error(e);
			});
			unSelected(options);
			//m.m.refreshQuery(elid);
		}
	}).fail(function (e) {
		console.error(e);
	});
});

/**
 * Копирование объекта в буфер обмена для операции копирования
 */
window.dispatcher.on('copyObj', options => {
	if (options.ids && options.ids.length == 0) {
		if (options.id) {
			options.ids = [options.id];
		} else {
			return;
		}
	}
	$.ajax({
		method: 'PUT'
		, url: 'buff/copy'
		, datatype: 'json'
		, data: {
			ids: options.ids
		}
	}).done(function (response) {
		if (response.ok === false) {
			return alert(response.error);
		}
		antNotification({
			type: 'info',
			message: 'Копирование в буфер',
			description: options.desc ? options.desc : 'Скопировали в буфер обмена для копирования',
		});
		window.dispatcher.emit('copyObj:End', options);
	}).fail(function (e) {
		console.error(e);
	});
});

/**
 * Копирование объекта в буфер обмена для операции переноса
 */
window.dispatcher.on('cut', options => {
	if (options.ids && options.ids.length == 0) {
		if (options.id) {
			options.ids = [options.id];
		} else {
			return;
		}
	}
	$.ajax({
		method: 'PUT'
		, url: 'buff/cut'
		, datatype: 'json'
		, data: {
			ids: options.ids
		}
	}).done(function (response) {
		if (response.ok === false) {
			return alert(response.error);
		}
		antNotification({
			type: 'info',
			message: 'Копирование в буфер',
			description: options.desc ? options.desc : 'Скопировали в буфер обмена для переноса',
		});
		window.dispatcher.emit('cut:End', options);
	}).fail(function (e) {
		console.error(e);
	});
});

/**
 * Вставить объекты из буфера
 */
window.dispatcher.on('paste', options => {
	var action = ''
		, parentTo = options.id ? (options.id.indexOf('_') > -1 ? options.id.split("_")[1] : options.id) : ''
	;
	switch (options.mode.toLowerCase()) {
		case 'ins2' :
			action = 'move';
			break;
		case 'ins' :
			action = 'copy';
			break;
	}
	if (!action || !parentTo) {
		return;
	}
	$.ajax({
		method: 'GET'
		, url: 'objects/' + action + '/' + parentTo
		, datatype: 'json'
	}).done(function (msg) {
		if (msg.ok === false) {
			return console.error(msg.error);
		}
	}).fail(function (e) {
		console.error(e);
	});
});

/**
 * Вставить связи объекты из буфера
 */
window.dispatcher.on('pasteLink', options => {
	var parentTo = options.id ? (options.id.indexOf('_') > -1 ? options.id.split("_")[1] : options.id) : '';
	$.ajax({
		method: 'GET'
		, url: 'objects/insRef/' + parentTo
		, datatype: 'json'
	}).done(function (msg) {
		if (msg.ok === false) {
			return console.error(msg.error);
		}

	}).fail(function (e) {
		console.error(e);
	});
});

window.dispatcher.on('nottree:deleteObj', options => {
	m.m.DelContNew(options.mode, options.id, [options.id], function (err, json) {
		if (err) {
			window.antNotification({
				type: 'error',
				message: 'Удаление объектов',
				description: 'Ошибка: ' + err.toString()
			});
		} else {
			if (json.ok === false) {
				window.antNotification({
					type: 'error',
					message: 'Удаление объектов',
					description: 'Ошибка: ' + json.error.toString()
				});
			} else {
				window.dispatcher.emit('tree:nodeRefresh', options.parent);
			}
		}
	});
});

/**
 * Добавить объект в Избраное и открыть Избраное в отдельной вкладке
 */
window.dispatcher.on('openFavoure:pasteLink', options => {
	$.ajax({
		method: 'GET',
		url: 'tree/' + y.p.BSLOGIN.replace(/-/g, 'o')
	})
		.done(function (data) {
			var favorites = data.result.filter(function (item) {
				return item.name.toLowerCase() == 'избранные'
			});
			if (!favorites[0]) {
				window.antNotification({
					type: 'error',
					message: 'Создание связи',
					description: 'У пользователя отсутствует контейнер "Избраные". Обратитесь к Администратору.'
				});
				return;
			}
			var favorite = favorites[0].id
				, tabId = ''
			;
			window.dispatcher.on('copyObj:End', options => {
				window.dispatcher.emit('pasteLink', { id: favorite });
			});
			window.dispatcher.emit('copyObj', { ids: [options.id] });
			//найдем и откроем папку избраное
			Object.keys(y.p.tab).forEach(item => {
				if (y.p.tab[item][0].split(',')[1] === 'DO' && y.p.tab[item][0].split(',')[3] === favorite) {
					tabId = item;
				}
			});
			if (tabId) {
				if (y.p.tabbar[y.p.tab[tabId][1]])
					y.p.tabbar[y.p.tab[tabId][1]].setTabActive(tabId);
			} else {
				y.f.AddDONew(y.c.FindWin(2), { root: favorite });
			}
		})
		.fail(function (e) {
			console.error('Не достаточно полномочий');
		});
});

/**
 * Удаление связи
 */
window.dispatcher.on('delRef', options => {
	if (options.ids.length == 0) {
		return;
	}
	delRef(options);
});
/**
 * Удаление массива объектов
 */
window.dispatcher.on('delObj', options => {
	if (options.ids.length == 0) {
		return;
	}
	var cb = function (err, json) {
	};
	if (options.cb && typeof options.cb === 'function') {
		cb = options.cb;
	}
	m.m.DelContNew(options.mode, options.ids[0], options.ids, cb);
	//m.m.DelCont(options.mode, options.ids[0], 1, options.ids.join(','), '');
	//delRef(options);
});

/**
 * Удалить связь оставить копию
 */
window.dispatcher.on('delRefInsObj', options => {
	if (!options.ids || !options.parent) {
		return;
	}
	$.ajax({
		method: 'POST'
		, url: '/objects/delRefInsObj/' + options.parent
		, dataType: 'json'
		, data: {
			ids: options.ids
		}
	}).done(function (msg) {
		if (response.ok === false) {
			return alert(response.error);
		}
		unSelected(options);
		response.message.forEach(function (item) {
			window.dispatcher.emit('tree:nodeRefresh', item);
		});
	}).fail(function (e) {
		console.error(e);
	});
});

/**
 * Обновить узел
 */
window.dispatcher.on('refreshNode', id => {
	//m.m.cChildsRefresh(id);
	if (typeof id === 'object') {
		id = id.id;
	}
	if (Array.isArray(id)) {
		id.forEach(function (item) {
			window.dispatcher.emit('tree:nodeRefresh', item);
		});
		window.dispatcher.emit('tree:nodeRefresh', '-LastObj');
	} else {
		window.dispatcher.emit('tree:nodeRefresh', id);
		window.dispatcher.emit('tree:nodeRefresh', '-LastObj');
	}
});

window.dispatcher.on('reIndexByParent', options => {
	$.ajax({
		method: 'POST'
		, url: 'tree/reIndexByParent/' + options.id
		, datatype: 'json'
	}).done(function (msg) {
		if (msg.ok === false) {
			return console.error(msg.error);
		}
	}).fail(function (e) {
		console.error(e);
	});
});


/**
 * Удаление объекта из корзины
 */
window.dispatcher.on('trash:removeObj', options => {
	objects.remove('ObjDel', options.ids, true, function (err, json) {
		if (err) {
			window.antNotification({
				type: 'error',
				message: 'Удаление объектов',
				description: 'Ошибка: ' + err.toString()
			});
		} else {
			if (json.ok === false) {
				window.antNotification({
					type: 'error',
					message: 'Удаление объектов',
					description: 'Ошибка: ' + json.error.toString()
				});
			} else {
				window.dispatcher.emit('tree:nodeRefresh', 'ObjDel');
			}
		}
	});
});

window.dispatcher.on('trash:restoreObj', options => {
	if (Array.isArray(options.ids)) {
		$.ajax({
			type: 'POST'
			, url: 'restore'
			, contentType: "application/json; charset=UTF-8"
			, data: JSON.stringify({ ids: options.ids })
			, success: function (json) {
			}
		});
	} else {
		callback(new Error('Идентификаторы не являются массивом.'));
	}
});

/**
 * Отправить запрос на сервер
 * обработчик контекстного меню
 */
window.dispatcher.on('getToServer', options => {
	switch (options.mode) {
		case 'weightIndex' :
			$.ajax({
				method: 'GET'
				, url: options.path ? options.path : 'weightIndex/' + options.id + '/' + options.action
				, datatype: 'json'
			})
				.done(function (res) {                                                                                   //TODO Продумать нотификацию
					if (options.cb && typeof options.cb == 'function') {
						cb(null, res);
					}
				})
				.fail(function (e) {                                                                                     //TODO Продумать нотификацию
					console.error(e.toString());
					if (options.cb && typeof options.cb == 'function') {
						cb(e.toString());
					}
				});
			break;
	}
});

/**
 * Добавить строку к таблице
 */
window.dispatcher.on('mCatTable:add', options => {
	const jQg = options.table;
	delete options.table;
	delete options.target;
	$.ajax({
		method: 'GET'
		, url: 'table/addRow/' + options.id + '/null?options=' + encodeURI(JSON.stringify(options))
		, datatype: 'json'
	})
		.done(function (res) {                                                                                   //TODO Продумать нотификацию
			jQg.trigger('reloadGrid');
		})
		.fail(function (e) {                                                                                     //TODO Продумать нотификацию
			console.error(e.toString());
		});
});

/**
 * Удалить строку из таблицы
 */
window.dispatcher.on('mCatTable:remove', options => {
	const jQg = options.table;
	delete options.table;
	delete options.target;
	$.ajax({
		method: 'GET'
		, url: 'table/deleteRow/' + options.id + '/' + options.rowid + '?options=' + encodeURI(JSON.stringify(options))
		, datatype: 'json'
	})
		.done(function (res) {                                                                                   //TODO Продумать нотификацию
			jQg.trigger('reloadGrid');
		})
		.fail(function (e) {                                                                                     //TODO Продумать нотификацию
			console.error(e.toString());
		});
});

/**
 * Сохранить профиль
 */
window.dispatcher.on('profile:save', options => {
	m.m.SaveProfile(y.p.nameProfile);
});

/**
 * Загрузить профиль по умолчанию
 */
window.dispatcher.on('profile:loadDefault', options => {
	m.m.OpenProfile2('~~~');
});

/**
 * Загрузить профиль
 */
window.dispatcher.on('profile:load', options => {
	var fl = new FindList({
		Ctrl: this
		, query: {
			parent: 'Obj'
			, typeId: String.fromCharCode(1) + '-Profile'
		}
	});
	fl.viewCatsFLMode('Выбор профиля', function (id, iRow, iCol, e) {
		$.ajax({
			method: 'GET'
			, url: 'propfile/loadPropfile/' + id
			, datatype: 'json'
		})
			.done(function (request) {
				if (request.ok === false) {
					window.antNotification({ type: 'error', message: 'Загрузка профиля.', description: request.error });
				}
				if (request.ok === true) {
					window.antNotification({
						type: 'success',
						message: 'Загрузка профиля.',
						description: request.message
					});
					m.m.OpenProfile2(id, '');
				}
			})
			.fail(function (e) {
				window.antNotification({ type: 'error', message: 'Загрузка профиля.', description: e.toString() });
			});
		if (fl.viewModal) {
			fl.viewModal.CloseWin();
		}
	});
	//m.m.OpenList('getProfileList^bsu2profile', 'curentProf=R001_2', 'Список профилей','1',function(a,b){m.m.OpenProfile2(b);});
});

/**
 *Сохранить как
 */
window.dispatcher.on('profile:saveAs', options => {
	m.m.SaveProfileAs(y.p.nameProfile);
});


window.dispatcher.on('kedr:printAct', options => {
	window.location = "/kedr/actChange?id=" + options.id
});


/**
 * Заморозить таб.
 */
window.dispatcher.on('tabFreeze', options => {
	if (options.tn && y.p.tab[options.tn] && y.p.tab[options.tn][0].split(',')[1] === 'PP') {
		//y.p.CaptionWinTab[y.p.GotoPPtab['Просмотр'].split(',')[1]] = y.p.CaptionWin['Просмотр'];
		delete y.p.GotoPPtab['Просмотр'];
	}
});

/**
 * Сформировать и вернуть и загрузить файл печатной формы по ид объекта
 */
window.dispatcher.on('getDoc', options => {
	window.dispatcher.emit('process', {
		initUser: {key: y.p.BSLOGIN}
		, desc: 'Формирование печатной формы запущено в фоновом режиме. Ожидайте загрузки.'
		, project: y.p.proj
		, action: 'process'
	});
	$.ajax({
		method: 'GET'
		, url: options.path + options.id + '?background=true'
		, datatype: 'json'
	})
		.done(function(request) {
			if (request.ok === false) {
				console.error(request.error);
			}
			if (request.ok === true) {
				if (request.text && Array.isArray(request.text)){
					request.text.forEach(function(item) {
						window.open('/file/' + item);
					})
				}
			}
		})
		.fail(function(e){
			window.dispatcher.emit('process', {
				initUser: {key: y.p.BSLOGIN}
				, desc: 'Формирование печатной формы завершилось с ошибкой ' + e.toString() + '.'
				, project: y.p.proj
				, action: 'process'
			});
			console.error(e);
		});
	//window.open(options.path + options.id);
});

/**
 * Завершение формирования документа
 */
window.dispatcher.on('endGenDocument', options => {
	if (options.user.replace(/-/ig, 'o') === y.p.BSLOGIN.replace(/-/ig, 'o')) {
		if (options.result) {
			if (!options.result.ok) {
				console.error(options.result.error);
				return;
			}
			if (options.result.text && Array.isArray(options.result.text)){
				options.result.text.forEach(function(item) {
					window.open('/file/' + item);
				})
			}
		}
	}
});

/**
 * Сформировать и вернуть и загрузить файл печатной формы по ид объекта для ОИЛ
 */
window.dispatcher.on('getDocOIL', options => {
	window.dispatcher.emit('process', {
		initUser: {key: y.p.BSLOGIN}
		, desc: 'Формирование печатной формы запущено в фоновом режиме. Ожидайте загрузки.'
		, project: y.p.proj
		, action: 'process'
	});
	$.ajax({
		method: 'POST'
		, url: options.path
		, data: {
			id: options.id
			, project: y.p.proj
		}
		, datatype: 'json'
	})
		.done(async function(request) {
			if (request.ok === false) {
				console.error(request.error);
			}
			if (request.ok === true) {
				if (request.message){

					if (options.path === 'genoil') {
						return getObject(options.id)
							.then((object) => {
								const name = (() => {
									const contractorValue = object.object.c0248fa20100d12d6e3cde956adfa28b2.contractor.value || '';
									const contractNum = object.object.c0248fa20100d12d6e3cde956adfa28b2.contractNum.value || '';
									return `${contractorValue}_${contractNum}_1ИЛ_ЛесфондГЕО.xlsx`.replace(/\//g, '_');
								})();

								window.open(`/file/${request.message}/${name}`);
							})
						;
					}

					window.open('/file/' + request.message);
				}
			}
		})
		.fail(function(e){
			window.dispatcher.emit('process', {
				initUser: {key: y.p.BSLOGIN}
				, desc: 'Формирование печатной формы завершилось с ошибкой ' + e.toString() + '.'
				, project: y.p.proj
				, action: 'process'
			});
			console.error(e);
		});
	//window.open(options.path + options.id);
});

// открыть в модальном окне
window.dispatcher.on('viewInModal', options => {
	var id = options.id
		, caption = options.caption
		, modalIcon = options.modalIcon
	;
	var mW = new ShowInMode('modal' + id);
	mW.form = id;
	mW.getCaption(caption);
	mW.getIcon(modalIcon);
	mW.Close = function (mwId, win) {
		y.modal.wins.forEachWindow(function (win) {
			if (win.getId() == mwId) {
				win.close();
			}
		});
	};
	mW.init(true);
});

/**
 * Обработчик события настройки стиля
 */
window.dispatcher.on('editStyle', options => { //TODO перенести в код Артема
	if (window.styleModule) {
		window.antModalWindow({
			title: 'Настройка стиля слоя.',
			content: 'Есть  активный интерфейс настройки стилей. Сохранить внесенный в нем изменения стиля?',
			type: 'info',
			onOk: () => {
				//TODO выйти с сохранением
				window.dispatcher.emit('editStyle', options);
			},
			onCancel: () => {
				//TODO выйти без сохранения
				window.dispatcher.emit('editStyle', options);
			},
			okText: 'Да',
			cancelText: 'Нет'
		}, $('#modalW-body').children('div'));
	}
	if (!options.sysLayerId) { //проверка системного имени слоя
		return window.antNotification({
			type: 'error',
			message: 'Настройка стиля слоя.',
			description: 'Недостаточно даных.'
		});
	}
	if (!getDataForStyleWidget) {
		return window.antNotification({
			type: 'error',
			message: 'Настройка стиля слоя.',
			description: 'Ошибка обработки.'
		});
	}
	getDataForStyleWidget({ id: options.sysLayerId, limit: 1 })
		.then(json => {
			console.log(json);
			var obj = {}
				, $div = y.f.addNewTab('', '140px', '', 'Настройка стиля слоя', y.c.FindWin(2), null, obj, 'LS');
			;
			/*if (json.layerJSON.object.layer.options.styleGeoServer && typeof json.layerJSON.object.layer.options.styleGeoServer === 'string') {
                json.layerJSON.object.layer.options.styleGeoServer = JSON.parse(json.layerJSON.object.layer.options.styleGeoServer);
            }*/
			if (!json.geoJSON || !json.alias || !json.layerJSON) {
				return window.antNotification({
					type: 'info',
					message: 'Настройка стиля слоя.',
					description: 'Для данного слоя невозможна настройка стиля.'
				});
			}
			var option = Object.assign(json, {
				container: $div.get(0)
				, options: { map: {} }
				, callbacks: {
					getPropValues: fieldDistinct
					, closeModule: closeModule
					, saveStyle: saveStyle
				}
			});
			//console.log(JSON.stringify(option));
			y.f.Loading($div.get(0), false);
			window.styleModule = window.initLayerStyleModule(option); //Вызов модуля
		})
		.catch(e => {
			//y.f.Loading($div.get(0), false);
			return window.antNotification({
				type: 'error',
				message: 'Настройка стиля слоя.',
				description: e.toString()
			});
		})
});

/**
 * Обработчик события настройки стиля
 */
window.dispatcher.on('editStyleCluster', options => { //TODO перенести в код Артема
	if (window.styleModuleC) {
		window.antModalWindow({
			title: 'Настройка стиля слоя.',
			content: 'Есть  активный интерфейс настройки стилей. Сохранить внесенный в нем изменения стиля?',
			type: 'info',
			onOk: () => {
				//TODO выйти с сохранением
				window.dispatcher.emit('editStyleCluster', options);
			},
			onCancel: () => {
				//TODO выйти без сохранения
				window.dispatcher.emit('editStyleCluster', options);
			},
			okText: 'Да',
			cancelText: 'Нет'
		}, $('#modalW-body').children('div'));
	}
	if (!options.sysLayerId) { //проверка системного имени слоя
		return window.antNotification({
			type: 'error',
			message: 'Настройка стиля кластера.',
			description: 'Недостаточно даных.'
		});
	}
	if (!getDataForStyleWidget) {
		return window.antNotification({
			type: 'error',
			message: 'Настройка стиля кластера.',
			description: 'Ошибка обработки.'
		});
	}
	let jsonCat = options.jsonCat;
	getDataForStyleWidget({ id: options.sysLayerId, limit: 1 })
		.then(json => {
			console.log(json);
			var obj = {}
				, $div = y.f.addNewTab('', '140px', '', 'Настройка стиля кластера', y.c.FindWin(2), null, obj, 'LS');
			;
			/*if (json.layerJSON.object.layer.options.styleGeoServer && typeof json.layerJSON.object.layer.options.styleGeoServer === 'string') {
                json.layerJSON.object.layer.options.styleGeoServer = JSON.parse(json.layerJSON.object.layer.options.styleGeoServer);
            }*/
			if (!json.geoJSON || !json.alias || !json.layerJSON) {
				return window.antNotification({
					type: 'info',
					message: 'Настройка стиля кластера.',
					description: 'Для данного слоя невозможна настройка стиля кластера.'
				});
			}
			var option = Object.assign(json, {
				container: $div.get(0)
				, options: { map: {} }
				, callbacks: {
					getPropValues: fieldDistinct
					, closeModule: closeModule
					, jsonCat: jsonCat
					, typeOfCluster: options.typeOfCluster
					, clusterProp: options.clusterProp
					, id: options.sysLayerId
					, saveStyle: saveStyle
				}
			});
			//console.log(JSON.stringify(option));
			y.f.Loading($div.get(0), false);
			window.styleModuleC = window.initLayerStyleCluster(option); //Вызов модуля
		})
		.catch(e => {
			//y.f.Loading($div.get(0), false);
			return window.antNotification({
				type: 'error',
				message: 'Настройка стиля кластера.',
				description: e.toString()
			});
		})
});


/**
 * Публикация слоя
 */
window.dispatcher.on('postingLayer', options => {
	var mW = new ShowInMode('getCont');
	mW.form = 'bf679ed137158d96a5b5fd4a874d91ea3';
	mW.getCaption('Выбор группы слоев');
	mW.init(true, function (err, draw) {
		var $draw = $('#' + draw);
		var sky = $draw.data('sky');
		if (sky) {
			sky.layerId = options.id;
		}
	});
});

/**
 * Показать пользователю нотификацию.
 */
window.dispatcher.on('sendNotification', options => {
	window.antNotification(options);
});

/**
 * Увеличить до слоя
 */
window.dispatcher.on('zoomToLayer', options => {
	if (options.sysLayerId) {
		//var layerName = $('.map-layers').children('.open-layer-info').children('label').children('#' + options.sysLayerId).html();
		/*var layerName = $('.map-layers').find('#' + options.sysLayerId).html();
        if (layerName === 'Текущее выделение'){
            layerName = 'currentSelection';
        }*/
		var layerName = options.sysLayerId;
		if (layerName === 'CurrentSelectionLayer') {
			layerName = 'currentSelection';
		}
		if (layerName && oe.map.map.layers[layerName] && oe.map.map.leaflet) {
			if (oe.map.map.layers[layerName].geoJSON || oe.map.map.layers[layerName].geojson) {
				var geoJsonLayer = null;
				if (oe.map.map.layers[layerName].geoJSON) {
					geoJsonLayer = L.geoJson(oe.map.map.layers[layerName].geoJSON);
				}
				if (oe.map.map.layers[layerName].geojson) {
					geoJsonLayer = L.geoJson(oe.map.map.layers[layerName].geojson);
				}
				var center = geoJsonLayer.getBounds().getCenter()
					, zoom = oe.map.map.leaflet.getBoundsZoom(geoJsonLayer.getBounds())
				;
				if (zoom > 17) {
					zoom = 17;
				}
				oe.map.map.leaflet.setView(center, zoom);
			} else {
				y.c.loadDictionarys({
					query: {
						parent: window?.projConfig?.gis?.layersFolder || 'A4893701o18732o64343o59776o642536'
						, type: String.fromCharCode(1) + 'layerType'
						, cats: String.fromCharCode(1) + 'layer'
						, props: String.fromCharCode(1) + 'name'
						, exprs: String.fromCharCode(1) + options.sysLayerId + '!'
					}, project: { 'object.layer.layerBBOX': 1, 'object.layer.source.key': 1 }
				}, function (json) {
					try {
						var layerId = Object.keys(json)[0];
						if (layerId) {
							if (json[layerId]['object.layer.source.key'] === 'wmsTileService') {
								var bbox = json[layerId]['object.layer.layerBBOX'];
								var corner1 = L.latLng(bbox.lat1, bbox.lng1)
									, corner2 = L.latLng(bbox.lat2, bbox.lng2)
									, bounds = L.latLngBounds(corner1, corner2)
									, center = bounds.getCenter()
									, zoom = oe.map.map.leaflet.getBoundsZoom(bounds)
								;
								if (zoom > 17) {
									zoom = 17;
								}
								oe.map.map.leaflet.setView(center, zoom);
							}
						}
					} catch (e) {
					}

				});
			}
		}
	}
});


/**
 * Экспорт слоя
 */
window.dispatcher.on('layerExportTo', options => {
	if (!options.id) {
		return window.antNotification({
			type: 'error',
			message: 'Экспорт слоя',
			description: 'Не указан слой'
		});
	}
	window.open(`/gis/gis/export/${options.format}/${options.id}`);
});

/**
 * Показать geoJSON в слое результатах поиска с фильтрацией по пользователю
 */
window.dispatcher.on('viewRezultToMapByUser', options => {
	if (options.user && options.user === y.p.BSLOGIN || options.user === y.p.BSLOGIN.replace(/-/g, 'o')) {
		options.geoJSON = JSON.parse(options.geoJSON);
		window.dispatcher.emit('viewRezultToMap', options)
	}
});

/**
 * Показать geoJSON в слое результатах поиска
 */
window.dispatcher.on('viewRezultToMap', options => {
	try {
		if (options.geoJSON) {
			oe.map.map.layers['Результаты поиска'].geojson = options.geoJSON;
		} else {
			oe.map.map.layers['Результаты поиска'].geojson = { "type": "FeatureCollection", "features": [] };
		}

		oe.map.map.layers['Результаты поиска'].redraw();
		oe.map.map.layers['Результаты поиска'].fromLayerId = options.fromLayerId;
		oe.map.map.layers['Результаты поиска'].query = options.query;
		oe.map.map.layers['Результаты поиска'].selectLayersId = options.selectLayersId;
		oe.map.map.CreateLayerFromSelect._updateDisabled(false);
		setTimeout(function () {
			window.dispatcher.emit('zoomToLayer', { sysLayerId: 'Результаты поиска' });
		}, 500);
	} catch (e) {
		window.antNotification({ type: 'error', message: 'Пересечение слоев.', description: e.toString() });
	}
});

/**
 * Показать на карте геометрию, если она есть
 */
window.dispatcher.on('viewGeometryInMap', options => {
	fetch(`/objects/${options.id}`)
		.then(
			function(response) {
				if (response.status !== 200) {
					console.log('Looks like there was a problem. Status Code: ' +
						response.status);
					return;
				}

				response.json().then(function(data) {
					if (data && data.object && data.object.object && data.object.object.geo && data.object.object.geo.geometry) {
						let LayerSysId = 'Результаты поиска';
						if (oe.map.map.selectLayers) {
							oe.map.map.selectLayers.forEach(function(item){
								$('.map-layers').children('.select').removeClass('select');
							});
							oe.map.map.selectLayers = [];
						}
						oe.map.map.selectLayers = [LayerSysId];
						if (!oe.map.map.layers[LayerSysId].layer) {
							oe.map.map.layers[LayerSysId].create();
							$('.map-layers').children('.padding_check').children('label').children('#' + LayerSysId).parent().children('input').prop('checked', 'checked');
							$('.map-layers').children('.padding_check').children('label').children('#' + LayerSysId).parent().children('#layer-visibility').css('opacity', '1');
						}
						if (oe.map.map.layers[LayerSysId].visible !== true) {
							oe.map.map.layers[LayerSysId].show();
							$('.map-layers').children('.padding_check').children('label').children('#' + LayerSysId).parent().children('input').prop('checked', 'checked');
							$('.map-layers').children('.padding_check').children('label').children('#' + LayerSysId).parent().children('#layer-visibility').css('opacity', '1');
						}
						$('.map-layers').children('.padding_check').children('label').children('#' + LayerSysId).parent().parent().addClass('select');
						window.dispatcher.emit('viewRezultToMap', {geoJSON: data.object.object.geo.geometry});
						window.dispatcher.emit('createMap', {});
					}
				});
			}
		)
		.catch(function(err) {
			console.log('Fetch Error :-S', err);
		});
});

/**
 *Создание объекта и слоя пересечения
 */
window.dispatcher.on('createLayer', options => {
	if (options.id === 'SearchLayer') { //создание из слоя результатов поиска
		if (oe.map.map.layers['Результаты поиска']) {
			var mW = new ShowInMode('createLayer');
			mW.form = 'f17b356e792faa0e7f25e3db4443d1e78';
			mW.getCaption('Создание объекта "Слой"');
			mW.Close = function (mwId, win) {
				y.modal.wins.forEachWindow(function (win) {
					if (win.getId() == mwId) {
						win.close();
						return;
					}
				});
			};
			mW.init(true, function (err, mwId) {
				var div = $('#' + mwId);
				console.log(div);
				if (div) {
					var skyLoc = div.data('sky');
					if (skyLoc) {
						skyLoc.o.o1.el('Введите название слоя.');
						skyLoc.o.btOk.clickBtOk = function () {
							var newName = this.parent.newName.el();
							mW.CloseWin();
							$.ajax({
								method: 'POST'
								, url: `gis/createLayerFromSearch`
								, data: {
									geoJSON: oe.map.map.layers['Результаты поиска'].geojson ? JSON.stringify(oe.map.map.layers['Результаты поиска'].geojson) : ''
									,
									layerIdFrom: oe.map.map.layers['Результаты поиска'].fromLayerId
									,
									query: oe.map.map.layers['Результаты поиска'].query ? JSON.stringify(oe.map.map.layers['Результаты поиска'].query) : ''
									,
									layerName: newName
									,
									selectLayersId: oe.map.map.layers['Результаты поиска'].selectLayersId ? JSON.stringify(oe.map.map.layers['Результаты поиска'].selectLayersId) : ''
								}
								, datatype: 'json'
							})
								.done(function (request) {
									if (request.ok === false) {
										window.antNotification({
											type: 'error',
											message: 'Создание слоя.',
											description: request.error
										});
										oe.map.reopen();
										return;
									}
									window.antNotification({
										type: 'success',
										message: 'Создание слоя.',
										description: 'Слой успешно создан'
									});

								})
								.fail(function (e) {
									return window.antNotification({
										type: 'error',
										message: 'Создание слоя.',
										description: e.toString()
									});
								});
						}
					}
				}
			});
		}
	}
});

/**
 * Пересечение слоев
 */
window.dispatcher.on('intersectLayers', options => {
	if (!oe.map.map.selectLayers || !oe.map.map.selectLayers.length) {
		return window.antNotification({
			type: 'error',
			message: 'Пересечение слоев.',
			description: 'Не выделено ни одного слоя.'
		});
	}
	var mW = new ShowInMode('createLayer');
	mW.form = 'f17b356e792faa0e7f25e3db4443d1e78';
	mW.getCaption('Создание слоя пересечения');
	mW.Close = function (mwId, win) {
		y.modal.wins.forEachWindow(function (win) {
			if (win.getId() == mwId) {
				win.close();
				return;
			}
		});
	};
	mW.init(true, function (err, mwId) {
		var div = $('#' + mwId);
		console.log(div);
		if (div) {
			var skyLoc = div.data('sky');
			if (skyLoc) {
				skyLoc.o.o1.el('Введите название слоя.');
				skyLoc.o.btOk.clickBtOk = function () {
					var newName = this.parent.newName.el();
					mW.CloseWin();
					var labelText = oe.map.map.selectLayers.map(function (item) {
						if ($('.padding_check').children('label').children('#' + item).html()) {
							return $('.padding_check').children('label').children('#' + item).html()
						} else {
							return null;
						}
					}).filter(function (item) {
						return item
					}).join(' и ');
					if ($('#frmTitleZ').children('#o23').length) {
						$('#frmTitleZ').children('#o23').data('control').updateContent({
							label: 'Расчет пересечения слоев:' + labelText,
							text: '0%'
						});
					}
					window.antNotification({
						type: 'info',
						message: 'Пересечение слоев.',
						description: 'Запущен фоновый процесс. Результат будет доступен в слое ' + newName
					});

					$.ajax({
						method: 'POST'
						, url: `gis/intersectLayers`
						, data: {
							selectLayers: JSON.stringify(oe.map.map.selectLayers)
							, newNameLayer: newName
							, labelText: 'Расчет пересечения слоев:' + labelText
						}
						, datatype: 'json'
					})
						.done(function (request) {
						})
						.fail(function (e) {
						});
				}
			}
		}
	});
});


window.dispatcher.on('vewDoc', options => {
	if (options.fileName) {
		window.open('/file/' + options.fileName);
	}
});

/**
 * identify по полигону из Текушего выделения
 */
window.dispatcher.on('identifyToLayerSearch', options => {
	options.geometry = oe.map.map.layers.currentSelection.layer.toGeoJSON();
	switch (options.geometry.type) {
		case 'FeatureCollection':
		case 'GeometryCollection':
			var tmp = null
				, arrName = 'features'
			;
			if (options.geometry.type === 'GeometryCollection') {
				arrName = 'geometries';
			}
			options.geometry[arrName].forEach(function (item) {
				if (tmp === null) {
					if (item.geometry.type.toLowerCase() === 'point' || item.geometry.type.toLowerCase() === 'polygon' || item.geometry.type.toLowerCase() === 'multipolygon') {
						tmp = item.geometry
					}
				}
			});
			options.geometry = tmp;
			break;
		case 'Feature':
			options.geometry = options.geometry.geometry;
			break;
	}
	oe.map.map.mode = 'identifyAll';
	var geoJsonLayer = L.geoJson(options.geometry);
	options.latlng = geoJsonLayer.getBounds().getCenter();
	oe.map.map.mapClick(options);
});

/**
 * Дублировать объект из текущего выделения
 */
window.dispatcher.on('map:dooble:currentSelection', options => {
	var objectId = oe.map.map.layers.currentSelection.objectId;
	if (!objectId) {
		return window.antNotification({
			type: 'error',
			message: 'Дублирование объектаю',
			description: 'Нет идентификатора исходного объекта.'
		});
	}
	$.ajax({
		method: 'GET'
		, url: `objects/${objectId}`
		, datatype: 'json'
	})
		.done(function (json) {
			if (json.ok === false) {
				return window.antNotification({
					type: 'error',
					message: 'Дублирование объекта.',
					description: 'Нет удалось получить исходный объект.'
				});
			}
			var opt = {
				project: 'lesfondgeo'
				, parentFolder: json.object.parent
				, object: JSON.stringify(json.object.object)
				, system: JSON.stringify(json.object.system)
			};
			$.ajax({
				method: 'PUT'
				, url: `/objects`
				, data: opt
				, datatype: 'json'
			})
				.done(function (json) {
					if (oe.map.map.layers.currentSelection.selectLayer) { //есть слой
						$.ajax({
							method: 'GET'
							, url: `gis/resaveWMSLayerBySystemName/${oe.map.map.layers.currentSelection.selectLayer}`
							, datatype: 'json'
						})
							.done(function (json) {
								oe.map.reopen();
							})
							.fail(function (e) {
								return window.antNotification({
									type: 'error',
									message: 'Дублирование объекта.',
									description: 'Нет удалось обновить слой.'
								});
							});
					}
				})
				.fail(function (e) {
					return window.antNotification({
						type: 'error',
						message: 'Дублирование объекта.',
						description: 'Нет удалось сохранить объект.'
					});
				});
		})
		.fail(function (e) {
			return window.antNotification({
				type: 'error',
				message: 'Дублирование объекта.',
				description: 'Нет удалось получить исходный объект.'
			});
		});
});

/**
 * Добвать или изменить данные в списке процессов
 */
window.dispatcher.on('progress:Board:Update', options => {
	/*console.log('//');
    console.log(options);
    console.log('//');*/
	if ($('#frmTitleZ').children('#o23').length) {
		$('#frmTitleZ').children('#o23').data('control').updateContent({ label: options.label, text: options.text });
	}
});

window.dispatcher.on('progress:Board:Remove', options => {
	if ($('#frmTitleZ').children('#o23').length) {
		$('#frmTitleZ').children('#o23').data('control').removeContent({ label: options.label, text: options.text });
	}
});


/**
 * Экспорт объекта
 */
window.dispatcher.on('exportObject', options => {
	var url = `exportObjects/${options.id}`;
	if (options.mode === 'exportOneObject') {
		url = `exportOneObject/${options.id}`;
	}
	window.open(url, "_blank");
});


/**
 * Команда занрузки файла с сервера
 */
window.dispatcher.on('loadFile', options => {
	if (options.user === y.p.BSLOGIN.replace(/-/g, 'o')) {
		var url = options.path;
		window.open(url, "_blank");
	}
});

/**
 * Создание слоя
 */
window.dispatcher.on('afterCreate:type:layerType', async (options) => {
	const map = oe.map.map;
	const { id } = options;
	map.addLayer(id);
});

/**
 * Удаление слоя
 */
window.dispatcher.on('afterDelete:type:layerType', async (options) => {
	const map = oe.map.map;
	const { id } = options;
	map.removeLayer(id);
});

/**
 * Обновление слоя
 */
window.dispatcher.on('afterUpdate:type:layerType', async (options) => {
	const map = oe.map.map;
	const { id } = options;
	map.updateLayer(id);
});

/**
 * Перемещение слоя
 */
window.dispatcher.on('afterMove:type:layerType', async (options) => {
	const map = oe.map.map;
	const { id } = options;
	map.moveLayer(id);
});

/**
 * Создание тега
 */
window.dispatcher.on('afterCreate:type:b6f9ba11081b5dc30c68d8ea24c0845ae', async (options) => {
	const map = oe.map.map;
	map.dispatch('updateMapTags');
});

/**
 * Удаление тега
 */
window.dispatcher.on('afterDelete:type:b6f9ba11081b5dc30c68d8ea24c0845ae', async (options) => {
	const map = oe.map.map;
	map.dispatch('updateMapTags');
});

/**
 * Обновление тега
 */
window.dispatcher.on('afterUpdate:type:b6f9ba11081b5dc30c68d8ea24c0845ae', async (options) => {
	const map = oe.map.map;
	map.dispatch('updateMapTags');
});

/**
 * Перемещение тега
 */
window.dispatcher.on('afterMove:type:b6f9ba11081b5dc30c68d8ea24c0845ae', async (options) => {
	const map = oe.map.map;
	map.dispatch('updateMapTags');
});
